import { fromEvent, Observable, partition, repeat } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

export function whenPageVisible(): <T>(source: Observable<T>) => Observable<T> {
  const visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  const [pageVisible$, pageHidden$] = partition(visibilitychange$, () => document.visibilityState === 'visible');

  return <T>(source: Observable<T>) => source.pipe(takeUntil(pageHidden$), repeat({ delay: () => pageVisible$ }));
}
